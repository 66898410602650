import countries from "utils/countries"

const tradingLocations = [
  {
    prop: "tradingName",
    required: true,
    grid: { xs: 12 },
    label: "Trading Name",
  },
  {
    prop: "addressLine",
    required: true,
    grid: { xs: 6 },
    label: "Address",
  },
  {
    prop: "addressLine2",
    required: true,
    grid: { xs: 6 },
    label: "Address Line 2",
  },
  {
    prop: "addressLine3",
    required: true,
    grid: { xs: 6 },
    label: "Address Line 3",
  },
  {
    prop: "city",
    required: true,
    grid: { xs: 6 },
    label: "City",
  },
  {
    prop: "state",
    required: true,
    grid: { xs: 6 },
    label: "State",
  },
  {
    prop: "pincode",
    required: true,
    grid: { xs: 6 },
    label: "Postcode",
  },
  {
    prop: "country",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Country",
    options: countries.map((country) => {
      return { value: country.label }
    })
  },
  {
    prop: "phone1",
    required: true,
    grid: { xs: 6 },
    label: "Phone Number",
  },
  {
    prop: "contactName",
    required: true,
    grid: { xs: 6 },
    label: "Contact Name",
  },
  {
    prop: "email",
    required: true,
    grid: { xs: 6 },
    label: "Email",
  },
  {
    prop: "packageId",
    required: true,
    grid: { xs: 6 },
    label: "Package ID",
  },
  {
    prop: "packageName",
    required: true,
    grid: { xs: 6 },
    label: "Package Name",
  },
  {
    prop: "packageDescription",
    required: true,
    grid: { xs: 12 },
    label: "Package Description",
  },
  {
    prop: "count",
    required: true,
    grid: { xs: 12 },
    label: "Count of terminals",
    fieldType: "number"
  },
  {
    prop: "localTranCurr",
    required: false,
    grid: { xs: 6 },
    label: "Local Transaction Currency",
    type: "select",
    options: [
      {
        label: "Not applicable",
        value: ""
      },
      {
        label: "SGD",
        value: "702"
      },
      {
        label: "USD",
        value: "840"
      },
      {
        label: "Multi-currency",
        value: "999"
      }
    ]
  },
  {
    prop: "foreignTranCurr",
    required: false,
    grid: { xs: 6 },
    label: "Foreign Transaction Currency",
    type: "select",
    options: [
      {
        label: "Not applicable",
        value: ""
      },
      {
        label: "SGD",
        value: "702"
      },
      {
        label: "USD",
        value: "840"
      },
      {
        label: "Multi-currency",
        value: "999"
      }
    ]
  }
]

export default tradingLocations;