import countries from "utils/countries";

const uboDetails = [
  {
    prop: "title",
    required: false,
    grid: { xs: 4 },
    label: "Title",
  },
  {
    prop: "firstName",
    required: false,
    grid: { xs: 4 },
    label: "First Name",
  },
  {
    prop: "lastName",
    required: false,
    grid: { xs: 4 },
    label: "Last Name",
  },
  {
    prop: "dateOfBirth",
    required: false,
    grid: { xs: 4 },
    label: "Date of Birth (DD-MM-YYYYY)",
  },
  {
    prop: "nationality",
    required: false,
    grid: { xs: 4 },
    label: "Nationality",
  },
  {
    prop: "shareholderPercentage",
    required: false,
    grid: { xs: 4 },
    label: "Shareholder Percentage",
    fieldType: "number"
  },
  {
    prop: "addressLine",
    required: false,
    grid: { xs: 12 },
    label: "Address Line 1",
  },
  {
    prop: "addressLine2",
    required: false,
    grid: { xs: 12 },
    label: "Address Line 2",
  },
  {
    prop: "addressLine3",
    required: false,
    grid: { xs: 12 },
    label: "Address Line 3",
  },
  {
    prop: "pincode",
    required: false,
    grid: { xs: 6 },
    label: "Postcode",
  },
  {
    prop: "city",
    required: true,
    grid: { xs: 6 },
    label: "City",
  },
  {
    prop: "state",
    required: true,
    grid: { xs: 6 },
    label: "State",
  },
  {
    prop: "country",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Country",
    options: countries.map((country) => {
      return { value: country.label }
    })
  },
  { 
    prop: "phone1",
    required: false,
    grid: { xs: 6 },
    label: "Phone",
  },
  {
    prop: "contactName",
    required: false,
    grid: { xs: 6 },
    label: "Contact Name",
  },
  {
    prop: "kycCategory",
    type: "select",
    grid: { xs: 12 },
    label: "KYC Category",
    options: [
      { value: "POI" },
      { value: "POA" }
    ]
  },
  {
    prop: "identityType",
    required: false,
    grid: { xs: 6 },
    label: "Type of KYC Document Submitted",
  },
  {
    prop: "identityValue",
    required: false,
    grid: { xs: 6 },
    label: "Value of KYC Document Submitted",
  },
]

export default uboDetails;